import React, { useContext, useState } from 'react'
import Icon from 'UI/Icon'
import AppContext from 'utils/app-context'

type Props = {
  onclick:() => void
  loading:boolean
}

const EntryScreenFirst = ({loading,onclick}: Props) => {
  const [selectOpened, setSelectOpened] = useState(false)
  const {t,setLanguage} = useContext(AppContext)
  const text = t('onBoardingScreen')
  return (
    <div className="screen-1">
            <div className="map-image">
              <img src="img/map.png" alt="карта" />
            </div>
            <h2 className="lang-title" style={{
              color:'#04F062',
              fontSize:32,
              fontFamily:"InterBold,sans-serif"
            }}>{text.getMore}</h2>
            <p className="lang-subtitle">{text.choseLang}</p>
            <div className="reward">
              <img src="img/coin-2.png" alt="" />
              <div className="reward-count">+ 50</div>
            </div>
            <div className="custom-select-wrapper" onClick={() => setSelectOpened(!selectOpened)}>
              <div className={`custom-select ${selectOpened && 'open'}`} 
              style={{marginBottom:selectOpened ? 120 :0}}>
                <div className="blockforjustify"></div>
                <div className="custom-select-trigger">{text.langName}</div>
                <div className="custom-select-arrow">
                  <img src="img/select-arrow-down.svg" className="arrow-down" />
                  <img src="img/select-arrow-top.svg" className="arrow-top" />
                </div>
                <div className="custom-options">
                  <span className="custom-option" onClick={() => setLanguage('en')}>
                    English
                  </span>
                  <span className="custom-option" onClick={() => setLanguage('ru')}>
                    Русский
                  </span>
                </div>
              </div>
            </div>
            <button className='friends-button' onClick={onclick} style={{
              color:'white',
              border:'none',
              fontSize:20,
              fontFamily:"InterMedium,sans-serif",
              maxWidth:338,
              position:'static',
              margin:'17px auto'
            }}>{loading ? <Icon icon='loading'/> : text.claimReward}</button>
          </div>
  )
}

export default EntryScreenFirst