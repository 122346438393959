import { RegData } from "api/send-settings";
import React, { useContext, useEffect, useRef, useState } from "react";
import AppContext from "utils/app-context";
import formatNum from "utils/format-num";
import animation from '../congrat-animation.json'
import Lottie, { LottieOptions, useLottie } from "lottie-react";
import { useNavigate } from "react-router-dom";
type Props = {
  data: RegData | null;
  page:number
};

const options:LottieOptions = {
  animationData:animation,
  className:"onboarding-lottie-animation",
  loop:false,
  autoplay:false
}

const EntryScreenSecond = ({ data ,page}: Props) => {
  const { t ,user} = useContext(AppContext);
  const navigation = useNavigate()
  const {View,play} = useLottie(options)
  const text = t("onBoardingScreen");
  const timer = useRef<null | NodeJS.Timeout>(null)

  const next = () => {
    let path = ''
    if (user?.everyday_task) path = '/daily-reward'
    else path = '/main/home'
    navigation(path);
  }

  useEffect(() => {
    console.log("🚀 ~ useEffect ~ page === 1:", page === 1)
    if(page === 1) {
      setTimeout(() => {
        play()
        window.addEventListener('click',next)
      }, 500);
      timer.current = setTimeout(next,3000)
    }

    return () => {
      window.removeEventListener('click',next)
      if(timer.current) clearTimeout(timer.current)
    }
  }, [page])
  return (
    <div className="screen-2" >
      {View}
      <div className="check">
        <h2
          className="check-title"
          style={{
            color: "#9C59CC",
            fontSize: 28,
            fontFamily: "InterBold,sans-serif",
            marginBottom:0
          }}
        >
          {text.congratulations}
        </h2>
        <h2>
          <span
            style={{
              fontSize: 24,
            }}
          >
            {text.oneOfFirst}
          </span>
          <br />
          <span style={{
            fontSize: 28,
            fontFamily: "InterBold,sans-serif",
          }}>
          {data?.count && formatNum(data.count)} / 1 000 000
          </span>
        </h2>
        <div>
        <p className="pre-reward">{text.Ureward}</p>
        <div className="rep-count big-num">+ {data?.reward} REP</div>
        </div>
        <div className="reward" >
          <img src="img/coin-2.png" alt="" />
          <div className="reward-count">+ 50</div>
        </div>
        <p
         style={{
          color: "#9C59CC",
          fontSize: 20,
        }}
        >{text.slogan}</p>
      </div>
    </div>
  );
};

export default EntryScreenSecond;
